import { Flex } from "common/widgets/advance/flex";
import React from "react";
import { Admin } from "..";
import { API } from "utils/api";
import { http } from "utils/http";
import { useState, useEffect } from "react";
import { Button } from "common/widgets/basic/button";
import { Modal } from "common/widgets/advance/modal";
import { Loader } from "common/loaders/loader";
import { Text } from "common/widgets/basic/text";
import { toast } from "react-toastify";
import { Toastify } from "common/loaders/toastify";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "context/AuthContext";

const headers = ["Name", "Email", "Message", "Action"];

/**
 * Renders the Contacts component.
 * This component displays a table of contacts and allows deleting contacts.
 */
export function Contacts() {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false); // State variable to track loading state
  const [contacts, setContacts] = useState([]); // State variable to store contacts data
  const [contactId, setContactId] = useState(""); // State variable to store the ID of the contact to be deleted
  const navigate = useNavigate();

  // Fetch the contacts data on component mount
  useEffect(() => {
    getContacts();
  }, []);

  /**
   * Fetches the contacts data from the server.
   * Updates the contacts state variable with the fetched data.
   */
  const getContacts = async () => {
    setIsLoading(true);
    const { res, data } = await http.post(
      API.contact.getAll,
      {},
      {
        token: currentUser?.token,
      }
    );
    if (res.status === 200) {
      // Set the contacts data in the state variable
      setContacts(data.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  /**
   * Handles the deletion of a contact.
   * Sends a delete request to the server with the contact ID.
   * Displays a success or error toast message based on the server response.
   * Fetches the updated contacts data after successful deletion.
   */
  const handleDeleteContact = async () => {
    setIsLoading(true);
    const { res, data } = await http.delete(
      API.contact.delete,
      {
        id: contactId,
      },
      {
        token: currentUser?.token,
      }
    );
    if (res.status === 200) {
      toast.success(data?.message);
      setIsLoading(false);
      // Fetch the updated contacts data after successful deletion
      getContacts();
      // Redirect to the contacts page after successful deletion
      navigate("/admin/contacts");
    } else {
      toast.error(data?.message);
      setIsLoading(false);
    }
  };

  return (
    <Admin text="Contacted Users">
      <Toastify />

      <Flex variant="rowCenterCenter" className="mt-10">
        <div className="w-full lg:w-[70rem] overflow-x-auto">
          <table className="min-w-full w-full bg-soft-peach">
            <thead className="bg-dark-charcoal  sticky top-0 z-10">
              <tr>
                {headers?.map((header, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-6 py-3 text-left text-base  font-semibold "
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/*  Map over the contacts array and render a table row for each contact */}
              {contacts?.map(({ _id, name, email, message }, index) => (
                <tr
                  key={_id}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  <td className="px-6  py-4 whitespace-nowrap text-sm  text-left">
                    {name}
                  </td>
                  <td className="px-6  py-4 whitespace-nowrap text-sm  text-left">
                    {email}
                  </td>
                  <td className="px-6  py-4 whitespace-nowrap text-sm  text-left">
                    {message}
                  </td>
                  <td>
                    <Button
                      size="xs"
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded ml-1"
                      link="#delete-user-modal"
                      onClick={() => setContactId(_id)} // Set the contact ID to be deleted in the state variable
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Modal to for the confirmation and delete */}
        <Modal name="delete-user-modal" size="sm">
          <div>
            <Text
              text="Delete Contact?"
              as="h2"
              size="2xl"
              weight="bold"
              className="text-center"
            />
            <Text
              text="Are you sure you want to delete this contact?"
              as="h2"
              className="text-center mt-4 mb-5"
              size="xl"
              weight="bold"
            />
            <Loader loading={isLoading} type="BarLoader" />

            <Button
              size="xs"
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-4 rounded ml-1 w-full mt-8"
              text="Delete"
              onClick={handleDeleteContact}
            />
          </div>
        </Modal>
      </Flex>
    </Admin>
  );
}
