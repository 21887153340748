"use client";

import React, { useState, useEffect } from "react";
import { Flex } from "../flex";
import { cva } from "class-variance-authority";
import { cn } from "utils/cn";
import { useHashHook } from "utils/hooks/useHashHook";
import { useLocation, useNavigate } from "react-router-dom";

// Define the Modal component
export function Modal({ children, className, variant, name, size }) {
  // Define the open state variable and its setter function
  const [open, setOpen] = useState(false);
  const hash = useHashHook();
  const navigate = useNavigate();
  const location = useLocation();

  // Run the effect when the hash or name changes
  useEffect(() => {
    if (hash === name) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [hash, name]);

  // Render the Modal component
  if (open) {
    return (
      <div className="relative overflow-y-scroll overflow-x-hidden after:fixed after:overflow-hidden after:z-[9] after:inset-0 after:bg-backdrop">
        <div className={cn(modalVariants({ variant, size, className }))}>
          <div className="z-[2] relative max-h-screen overflow-y-auto p-4">
            <Flex variant="rowEndCenter">
              <button
                onClick={() => {
                  navigate(location.pathname);
                }}
                className="text-3xl"
              >
                x
              </button>
            </Flex>
            <div className="mt-2 mb-4"> {children}</div>
          </div>
        </div>
      </div>
    );
  } else return null;
}

// Define the modalVariants object using the cva function
const modalVariants = cva("", {
  variants: {
    variant: {
      standard:
        "fixed w-[50rem]  z-[99] block overflow-y-auto overflow-x-hidden -translate-y-1/4 bg-soft-peach shadow-grey mt-4 mb-auto mx-auto rounded-lg top-1/4 inset-x-0",
    },
    size: {
      xs: "w-[95%] md:w-[20rem]",
      sm: "w-[95%] md:w-[30rem]",
      md: "w-[95%] md:w-[40rem]",
      lg: "w-[95%] md:w-[80%] lg:w-[50rem]",
      xl: "w-[95%] md:w-[80%] lg:w-[60rem]",
    },
  },
  defaultVariants: {
    variant: "standard",
    size: "md",
  },
});
