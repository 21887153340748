import React, { useState } from "react";
import { Button } from "common/widgets/basic/button";
import { Input } from "common/widgets/basic/input";
import { Text } from "common/widgets/basic/text";
import { Flex } from "common/widgets/advance/flex";
import { toast } from "react-toastify";
import { Toastify } from "common/loaders/toastify";
import { API } from "utils/api";
import { http } from "utils/http";
import { Loader } from "common/loaders/loader";
import { validate } from "email-validator";

export function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // It is without token, as it is public anyone can contact the admin
  const addContact = async () => {
    if (!validate(email)) {
      toast.error("Please enter a valid email");
      return;
    }
    if (!name || !email || !message) {
      toast.error("Please fill all the fields");
      return;
    }
    setIsLoading(true);
    const { res, data } = await http.post(API.contact.add, {
      name,
      email,
      message,
    });
    if (res.status === 201) {
      toast.success(data?.message);
      setIsLoading(false);
    } else {
      toast.error(data?.message);
      setIsLoading(false);
    }
  };

  // updated event handler// limit the input to 250 characters
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 250) {
      setMessage(inputValue);
    }
  };

  return (
    <Flex variant="colCenterCenter" className="mb-14">
      <Toastify />
      <Flex
        variant="colCenterStart"
        className="p-5 sm:p-10 shadow-grey w-[calc(100%-1rem)] mt-10  mx-2 sm:w-[30rem]  rounded-xl"
      >
        <Text
          as="h1"
          className="text-center w-full my-2"
          text="Contact Us"
          color="dark-blue"
          size="3xl"
          weight="semiBold"
        />
        <Input
          placeholder="Enter your name"
          variant="solid-light-grey"
          label="Name"
          className="w-full sm:w-96"
          containerClass="w-full sm:w-96 mt-2"
          value={name}
          type="text"
          onChange={(e) => setName(e.target.value)} // get the values in state
        />
        <Input
          placeholder="Enter your email"
          variant="solid-light-grey"
          label="Email"
          className="w-full sm:w-96"
          containerClass="w-full sm:w-96 mt-2"
          value={email}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />

        <Input
          variant="solid-light-grey"
          label="Message"
          className="w-full sm:w-96"
          containerClass="w-full sm:w-96 mt-2"
          value={message}
          type="text"
          placeholder="Up to 250 characters"
          onChange={handleInputChange} // updated event handler
          textArea={true}
          rows={4}
          cols={50}
        />

        <Loader loading={isLoading} type="BarLoader" />
        <Button
          variant="solid-dark-blue"
          text="Contact"
          size="md"
          className="text-white w-full sm:w-96 mt-5"
          onClick={addContact}
        />
      </Flex>
    </Flex>
  );
}
