// Import React, useState, and useEffect from 'react'
import React, { useState, useEffect } from "react";

// Define the Cookie component
export function Cookie() {
  // Define state variable showCookieBanner and its setter function setShowCookieBanner
  const [showCookieBanner, setShowCookieBanner] = useState(true);

  // Run the effect only once, when the component mounts
  useEffect(() => {
    // Check if the user has already accepted cookies
    const hasAcceptedCookies = localStorage.getItem("acceptedCookies");
    // If the user has accepted cookies, hide the cookie banner
    if (hasAcceptedCookies) {
      setShowCookieBanner(false);
    }
  }, []);

  // Define the handleAccept function
  const handleAccept = () => {
    // Set the acceptedCookies item in localStorage to 'true'
    localStorage.setItem("acceptedCookies", "true");
    // Hide the cookie banner
    setShowCookieBanner(false);
  };

  // Define the handleDecline function
  const handleDecline = () => {
    // Set the acceptedCookies item in localStorage to 'false'
    localStorage.setItem("acceptedCookies", "false");
    // Hide the cookie banner
    setShowCookieBanner(false);
  };

  // Render the Cookie component
  return (
    <div>
      {/* Render the cookie banner if showCookieBanner is true */}
      {showCookieBanner && (
        <div className="fixed bottom-0 w-full bg-gray-800 text-white p-2 text-center">
          <p className="text-sm">
            We use cookies to ensure you get the best experience on our website.{" "}
            {/* Render the Accept button */}
            <button onClick={handleAccept} className="underline">
              Accept
            </button>{" "}
            {/* Render the Decline button */}
            <button onClick={handleDecline} className="underline">
              Decline
            </button>
          </p>
        </div>
      )}
    </div>
  );
}
