import React from "react";
import { Flex } from "common/widgets/advance/flex";
import { Text } from "common/widgets/basic/text";
import { Button } from "common/widgets/basic/button";
import { Link } from "react-router-dom";

export function Navbar() {
  return (
    <Flex
      variant="rowBetweenCenter"
      className="px-0 sm:px-4 shadow-grey py-4 flex-col sm:flex-row gap-4"
    >
      <Link to="/">
        <Text as="h1" variant="leading" weight="bold" size="2xl">
          Home
        </Text>
      </Link>
      <Flex variant="rowStartCenter" className="gap-4">
        <Button text="About Us" link="/about" variant="standard" />
        <Button text="Contact Us" link="/contact" variant="standard" />
        <Button text="Login" link="/login" />
      </Flex>
    </Flex>
  );
}
