import headerImg from "../assets/imgs/header.png";
import contact from "../assets/imgs/contact.jpeg";
import location from "../assets/imgs/location.jpg";
import header2 from "../assets/imgs/header-2.jpg";

export const imgs = {
  headerImg,
  contact,
  location,
  header2,
};
