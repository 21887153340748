import React, { useEffect, useState } from "react";
import { Dashboard } from "..";
import { Flex } from "common/widgets/advance/flex";
import axios from "axios";
import { useParams } from "react-router";
import moment from "moment";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {
  width: "95%",
  height: "100%",
};

function ViewDevice() {
  //  we get the if from the URL
  const { id } = useParams();
  // Define the required state
  const [location, setLocation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [address, setAddress] = useState("");
  const [deviceStatus, setDeviceStatus] = useState({
    formattedTime: "",
    isWithinLastHour: false,
  });

  // Tracer URL
  const originalUrl = `https://p-solutions.es:8083`;
  const url = `${originalUrl}/api/positions/?deviceId=${id}`;
  const email = "omartin01@uoc.edu";
  const password = "Traccar123456";
  const credentials = btoa(`${email}:${password}`);
  // Pass credentials to fetch the user

  // Initialize the device location on initial mount
  useEffect(() => {
    getDeviceLocation();
  }, []);

  // Periodically fetch the device location
  useEffect(() => {
    // Fetch device location every 10 seconds

    const interval = setInterval(() => {
      getDeviceLocation();
    }, 10000);

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(interval);
    };
  }, [location]);

  // Get address from google api with lang and lat

  const getAddress = async (lat, lng) => {
    try {
      const res = axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDlVOodXI2zifZnS4PkaLF92Al12zhO6bQ`
      );
      const resData = await res;
      setAddress(resData?.data?.results[0].formatted_address);
    } catch (error) {
      console.log(error);
    }
  };

  // Get location of a device from traccer
  const getDeviceLocation = async () => {
    try {
      const res = axios.get(url, {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const resData = await res;
      if (resData.status === 200) {
        //  When status if ok, populate the state with required values
        getAddress(resData.data[0]?.latitude, resData.data[0]?.longitude);
        setLocation(resData.data);
        setIsLoading(false);
        deviceLastTime(resData.data[0].serverTime);
      }
    } catch (error) {
      setIsLoading(false);
      setLocation([]);
      console.log(error);
    }
  };

  // Get the last time the device was seen
  const deviceLastTime = (time) => {
    if (!time) {
      return;
    }
    const currentTime = moment();
    const deviceTime = moment(time);
    const diffInMinutes = currentTime.diff(deviceTime, "minutes");
    const isWithinLastHour = diffInMinutes <= 60;
    setDeviceStatus({
      formattedTime: deviceTime.format("MMMM Do YYYY, h:mm:ss a"),
      isWithinLastHour: isWithinLastHour,
    });
  };

  return (
    <Dashboard text="">
      <Flex
        variant="colStartStart"
        className="p-5 sm:p-10 shadow-grey w-[calc(100%-1rem)]  h-[100vh] mx-2 rounded-xl"
      >
        {location.length > 0 && (
          <>
            {deviceStatus.isWithinLastHour && (
              <div
                className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4"
                role="alert"
              >
                <p className="font-bold">Device is online</p>
                <p>Device last seen at {deviceStatus.formattedTime}</p>
                <p>Current Address: {address}</p>
              </div>
            )}
            {!deviceStatus.isWithinLastHour && (
              <div
                className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4"
                role="alert"
              >
                <p className="font-bold">Device is offline</p>
                <p>Device last seen at {deviceStatus.formattedTime}</p>
                <p>Current Address: {address}</p>
              </div>
            )}
          </>
        )}

        {/* Google map integration */}
        {location.length > 0 && (
          <div>
            <Map
              google={window?.google}
              style={mapStyles}
              zoom={8}
              initialCenter={{
                lat: location[0]?.latitude,
                lng: location[0]?.longitude,
              }}
            >
              <Marker
                position={{
                  lat: location[0]?.latitude,
                  lng: location[0]?.longitude,
                }}
              />
            </Map>
          </div>
        )}

        {/* If no location data is found, show the message */}
        {location.length === 0 && !isLoading && (
          <div className="text-center mt-40 w-full">
            <p className="text-xl font-medium text-red-500 text-center">
              No location data found, Please turn on the Tracccer device, add a
              serverURL "http://p-solutions.es:8082" , and start the service.{" "}
              <br /> Make sure the device is connected to the internet, and you
              have added correct device ID, feel free to contact the admin if
              the issue persists.
            </p>
          </div>
        )}
      </Flex>
    </Dashboard>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDlVOodXI2zifZnS4PkaLF92Al12zhO6bQ",
})(ViewDevice);
