// Import the necessary dependencies
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

/**
 * Custom hook that retrieves the hash value from the URL and updates it when the URL changes.
 * @returns {string} The current hash value.
 */
// Define the useHashHook function
export const useHashHook = () => {
  // Get the URL parameters
  const params = useParams();
  // Define the state variable for the hash value
  const [hash, setHash] = useState("");

  // Update the hash value when the URL changes
  useEffect(() => {
    // Get the current hash value from the URL
    const currentHash = window.location.hash.replace("#", "");
    // Set the hash value in the state
    setHash(currentHash);
  }, [params]);

  // Return the current hash value
  return hash;
};
