import React from "react";
import { Navbar } from "./navbar";
import { Footer } from "./footer";

// Define the Landing component
export function Landing({ children }) {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
}
