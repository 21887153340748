import { Flex } from "common/widgets/advance/flex";
import React from "react";
import { Admin } from "..";
import { Button } from "common/widgets/basic/button";
import { Text } from "common/widgets/basic/text";
import { Modal } from "common/widgets/advance/modal";
import { http } from "utils/http";
import { API } from "utils/api";
import { useState, useEffect } from "react";
import { Loader } from "common/loaders/loader";
import { Toastify } from "common/loaders/toastify";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "context/AuthContext";
const headers = ["Email", "Password", "Action"];

export function AllUsers() {
  // Get the current user from the AuthContext
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.token) {
      getUsers();
    }
  }, [currentUser]);

  //  Get all the users from the database

  const getUsers = async () => {
    setIsLoading(true);
    const { res, data } = await http.get(
      API.admin.user.getAll,
      {},
      {
        token: currentUser.token,
      }
    );
    if (res.status === 200) {
      setUsers(data.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  // Handle delete user from our database
  const handleDeleteUser = async () => {
    setIsLoading(true);
    const { res, data } = await http.delete(
      API.admin.user.delete,
      {
        id: userId,
      },
      {
        token: currentUser.token,
      }
    );
    if (res.status === 200) {
      toast.success(data?.message);
      setIsLoading(false);
      getUsers();
      navigate("/admin");
    } else {
      toast.error(data?.message);
      setIsLoading(false);
      setIsLoading(false);
    }
  };

  return (
    <Admin text="All Users">
      <Toastify />
      <Flex variant="rowCenterCenter" className="mt-10">
        <div className="w-full lg:w-[70rem] overflow-x-auto">
          <table className="min-w-full w-full bg-soft-peach">
            <thead className="bg-dark-charcoal  sticky top-0 z-10">
              <tr>
                {headers?.map((header, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-6 py-3 text-left text-base  font-semibold "
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users?.map(({ _id, email, password }, index) => (
                <tr
                  key={_id}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  <td className="px-6  py-4 whitespace-nowrap text-sm  text-left">
                    {email}
                  </td>
                  <td className="px-6  py-4 whitespace-nowrap text-sm  text-left">
                    {password}
                  </td>
                  <td className="w-full ">
                    <td className="py-4 w-full">
                      <Button
                        link={`/admin/update-user/${_id}`}
                        size="xs"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded "
                      >
                        Edit
                      </Button>
                      <Button
                        size="xs"
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded ml-1"
                        link="#delete-user-modal"
                        onClick={() => setUserId(_id)}
                        disabled={currentUser._id === _id}
                      >
                        {currentUser._id === _id ? "Admin" : "Delete"}
                      </Button>
                    </td>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Modal name="delete-user-modal" size="sm">
          <div>
            <Text
              text="Delete User?"
              as="h2"
              size="2xl"
              weight="bold"
              className="text-center"
            />
            <Text
              text="Are you sure you want to delete this user?"
              as="h2"
              className="text-center mt-4 mb-5"
              size="xl"
              weight="bold"
            />
            <Loader loading={isLoading} type="BarLoader" />
            <Button
              size="xs"
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-4 rounded ml-1 w-full mt-8"
              text="Delete"
              onClick={handleDeleteUser}
            />
          </div>
        </Modal>
      </Flex>
    </Admin>
  );
}
