import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Import the ToastContainer component and the CSS file for the toast notifications

export function Toastify({ position = "top-center" }) {
  return (
    <ToastContainer
      position={position}
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
