import React from "react";
import { Text } from "common/widgets/basic/text";
import { Flex } from "common/widgets/advance/flex";
import { imgs } from "data/static/imgData";
import { Link } from "react-router-dom";

export function About() {
  const { header2 } = imgs;
  return (
    <Flex variant="rowCenterCenter" className="my-32">
      <Flex
        variant="rowCenterStart"
        className="flex-col lg:flex-row gap-16 w-full lg:w-[70rem] lg:p-0 p-5"
      >
        <img
          src={header2}
          alt="contact"
          className="w-full h-full sm:w-80 sm:h-80 lg:w-96 lg:h-72 rounded-lg"
        />
        <div>
          <Flex variant="colStartStart" className="gap-2">
            <Text
              as="h1"
              variant="heading"
              text="About this site"
              weight="small"
              size="lg"
              className="text-dark-grey border-b-2 self-start"
            />

            <Text
              variant="leading"
              as="h1"
              weight="bold"
              size="3xl"
              className="self-start"
            >
              <span className="text-purple-blue"> Welcome </span> to my site.
            </Text>

            <Text
              as="p"
              variant="base"
              weight="small"
              size="small"
              className="text-dark-grey"
            >
              Welcome to my site. This is my personal project, which is also
              part of an educational project, but is intended for my personal
              use, as well as a few close individuals. As such, there’s no
              company behind this site, no commercial use, limited support and
              the site is not open for the general public, so self-registration
              of users is disabled.
              <br />
              Once most visitors left, let me explain what this site is about.
              The purpose behind this project is to have a place, easily
              accessible from any device, where you can check the location of
              your family, friends, belongings… or even so others can track
              yourself! This can be useful in many scenarios, every person has
              its own reason to use it, but examples include:
            </Text>

            <ul className="text-dark-grey">
              <li>-Location of lost, dependent, or wandering persons.</li>
              <li>-Location of lost or escaped pets.</li>
              <li>-Location of missing or theft vehicles.</li>
              <li>
                -Location for security purposes, such as pinpointing the current
                position of a mountain climber.
              </li>
              <li>-Location for research or statistical reasons.</li>
            </ul>
            <Text
              as="p"
              variant="base"
              weight="small"
              size="small"
              className="text-dark-grey"
            >
              In order to do this, we are using our smartphones, or re-purposing
              old smartphones (depending of scenario) to avoid additional costs
              in hardware, as well as to reduce carbon footprint of the whole
              project.
              <br />
              This website is completely free to use for allowed users, so no
              other expenses besides a smartphone with data plan are needed in
              order to make use of it. I hope you find it useful, enjoy!
            </Text>
            <Text
              as="p"
              variant="base"
              weight="small"
              size="small"
              className="text-dark-grey"
            >
              If you need an account, or you need support with an existing one,
              please{" "}
              <Link to="/contact" className="text-red-500">
                contact
              </Link>
            </Text>
            <Text
              as="p"
              variant="base"
              weight="small"
              size="small"
              className="text-dark-grey"
            >
              If you already have an accout, please read the instructions and
              FAQ, available{" "}
              <Link
                to="https://p-solutions.es/Doc.pdf"
                className="text-red-500"
              >
                here{" "}
              </Link>
              to download
            </Text>
            <Text
              as="p"
              variant="base"
              weight="small"
              size="small"
              className="text-dark-grey"
            >
              If you are ready to start{" "}
              <Link to="/login" className="text-red-500">
                go to login
              </Link>
            </Text>
          </Flex>
        </div>
      </Flex>
    </Flex>
  );
}
