import React from "react";
import { Landing } from "./landing";

const layouts = {
  Landing,
};

// Define the Layout component
export function Layout({ type, children }) {
  const LayoutComponent = layouts[type];

  return <LayoutComponent>{children}</LayoutComponent>;
}
