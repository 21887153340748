import React, { useState, useEffect } from "react";
import { Button } from "common/widgets/basic/button";
import { Input } from "common/widgets/basic/input";
import { Text } from "common/widgets/basic/text";
import { Flex } from "common/widgets/advance/flex";
import { toast } from "react-toastify";
import { Toastify } from "common/loaders/toastify";
import { Dashboard } from "..";
import { API } from "utils/api";
import { http } from "utils/http";
import { Loader } from "common/loaders/loader";
import { useAuth } from "context/AuthContext";
import { useParams } from "react-router";
import axios from "axios";

export function EditDevice() {
  const { id } = useParams();
  const { currentUser } = useAuth();

  // States
  const [name, setName] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [deviceIndex, setDeviceIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // Effect
  useEffect(() => {
    getDevice();
  }, [id]);

  const getDevice = async () => {
    const { res, data } = await http.post(
      API.device.get,
      {
        userId: currentUser?._id,
        deviceId: id,
      },
      {
        token: currentUser?.token,
      }
    );
    if (res.status === 200) {
      setName(data.data.name);
      setDeviceId(data.data.deviceId);
      setDeviceIndex(data.data.id);
    }
  };
  // Update in Our database
  const updateDevice = async () => {
    setIsLoading(true);
    const { res, data } = await http.patch(
      API.device.update,
      {
        userId: currentUser?._id,
        deviceId: id,
        name: name,
      },
      {
        token: currentUser?.token, // Pass token to recognize the user, protected route
      }
    );
    if (res.status === 200) {
      toast.success(data?.message);
      setIsLoading(false);
    } else {
      toast.error(data?.message);
      setIsLoading(false);
    }
  };

  // Traccer URL
  const originalUrl = `https://p-solutions.es:8083`;
  const updateUrl = `${originalUrl}/api/devices/${deviceIndex}`;
  const email = "omartin01@uoc.edu";
  const password = "Traccar123456";
  const credentials = btoa(`${email}:${password}`);

  const updateTraccerDevice = async () => {
    try {
      const res = axios.put(
        updateUrl,
        {
          id: deviceIndex,
          name: name,
          uniqueId: deviceId,
          status: "string",
          disabled: false,
          lastUpdate: "2019-08-24T14:15:22Z",
          positionId: 0,
          groupId: 0,
          phone: "string",
          model: "string",
          contact: "string",
          category: "string",
          attributes: {},
        },
        {
          headers: {
            Authorization: `Basic ${credentials}`,
            "Content-Type": "application/json",
          },
        }
      );
      const resData = await res;
      if (resData.status === 200) {
        // As soon as the device is updated on the Traccer, update it in our database as well
        updateDevice();
        toast.success("Device updated to the Traccer successfully!");
      } else {
        toast.error("Failed to update device");
      }
    } catch (error) {
      toast.error("Failed to update device");
    }
  };

  return (
    <Dashboard text="">
      <Flex variant="colCenterCenter" className="mb-14">
        <Toastify />
        <Flex
          variant="colCenterStart"
          className="p-5 sm:p-10 shadow-grey w-[calc(100%-1rem)]   mx-2 sm:w-[30rem]  rounded-xl"
        >
          <Text
            as="h1"
            className="text-center w-full"
            text="Update Device"
            color="dark-blue"
            size="3xl"
            weight="semiBold"
          />
          <Input
            variant="solid-light-grey"
            label="Name"
            className="w-full sm:w-96"
            containerClass="w-full sm:w-96 mt-4"
            value={name}
            type="text"
            placeholder="name"
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            variant="solid-light-grey"
            label="Device ID"
            className="w-full sm:w-96"
            containerClass="w-full sm:w-96 mt-4"
            value={deviceId}
            type="text"
            placeholder="device id"
            onChange={(e) => setDeviceId(e.target.value)}
            disabled={true}
          />

          <Loader loading={isLoading} type="BarLoader" />
          <Button
            variant="solid-dark-blue"
            text="Update"
            size="md"
            className="text-white w-full sm:w-96 mt-10 mb-8"
            onClick={updateTraccerDevice}
          />
        </Flex>
      </Flex>
    </Dashboard>
  );
}
