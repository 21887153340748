import { Login } from "auth/login";
import "./App.css";
import { Home } from "sections/landing/home";
import { Contact } from "sections/landing/contact";
import { About } from "sections/landing/about";
import { Layout } from "./layout";
import { AddUser } from "sections/admin/addUser";
import { AllUsers } from "sections/admin/allUsers";
import { Contacts } from "sections/admin/contacts";
import { EditUser } from "sections/admin/editUser";
import { AllDevices } from "sections/dashboard/allDevices";
import { AddDevice } from "sections/dashboard/addDevice";
import { EditDevice } from "sections/dashboard/editDevice";
import ViewDevice from "sections/dashboard/viewDevice";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "context/AuthContext";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Define route */}
          <Route
            path="/login"
            element={
              <Layout type="Landing">
                <Login />
              </Layout>
            }
          />
          <Route
            path="/contact"
            element={
              <Layout type="Landing">
                <Contact />
              </Layout>
            }
          />
          <Route
            path="/about"
            element={
              <Layout type="Landing">
                <About />
              </Layout>
            }
          />
          <Route path="/admin" element={<AllUsers />} />
          <Route path="/admin/add-user" element={<AddUser />} />
          <Route path="/admin/update-user/:id" element={<EditUser />} />
          <Route path="/admin/contacts" element={<Contacts />} />
          <Route path="/dashboard" element={<AllDevices />} />
          <Route path="/dashboard/add-device" element={<AddDevice />} />
          <Route path="/dashboard/update-device/:id" element={<EditDevice />} />
          {/* Path with params :/id, and we will get it with const {id} = useParams() */}
          <Route path="/dashboard/view-device/:id" element={<ViewDevice />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
