import React, { useState } from "react";
import { Button } from "common/widgets/basic/button";
import { Input } from "common/widgets/basic/input";
import { Text } from "common/widgets/basic/text";
import { Flex } from "common/widgets/advance/flex";
import { toast } from "react-toastify";
import { Toastify } from "common/loaders/toastify";
import { Admin } from "..";
import { http } from "utils/http";
import { API } from "utils/api";
import { Loader } from "common/loaders/loader";
import { useAuth } from "context/AuthContext";
import { validate } from "email-validator";

export function AddUser() {
  const { currentUser } = useAuth();
  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Add user with required credentials
  const addUser = async () => {
    if (!validate(email)) {
      toast.error("Please enter a valid email");
      return;
    }
    setIsLoading(true);
    const { res, data } = await http.post(
      API.admin.user.add,
      {
        email,
        password,
        type,
      },
      {
        token: currentUser.token,
      }
    );
    if (res.status === 201) {
      toast.success(data?.message);
      setIsLoading(false);
    } else {
      toast.error(data?.message);
      setIsLoading(false);
    }
  };

  return (
    <Admin text="">
      <Flex variant="colCenterCenter" className="mb-14">
        <Toastify />
        <Flex
          variant="colCenterStart"
          className="p-5 sm:p-10 shadow-grey w-[calc(100%-1rem)] mx-2 sm:w-[30rem]  rounded-xl"
        >
          <Text
            as="h1"
            className="text-center w-full"
            text="Add New User"
            color="dark-blue"
            size="3xl"
            weight="semiBold"
          />
          <Input
            variant="solid-light-grey"
            label="Email"
            className="w-full sm:w-96"
            containerClass="w-full sm:w-96 mt-2"
            value={email}
            type="email"
            placeholder="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            variant="solid-light-grey"
            label="Password"
            className="w-full sm:w-96"
            containerClass="w-full sm:w-96 mt-2"
            value={password}
            type="password"
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="flex items-center my-2">
            <input
              checked={type === "user"}
              id="default-radio-1"
              type="radio"
              name="user"
              value={type}
              onChange={(e) => setType("user")}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="default-radio-1"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              User
            </label>
          </div>
          <div className="flex items-center">
            <input
              checked={type === "admin"}
              id="default-radio-2"
              type="radio"
              name="admin"
              value={type}
              onChange={(e) => setType("admin")}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="default-radio-2"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Admin
            </label>
          </div>

          <Loader loading={isLoading} type="BarLoader" />
          <Button
            variant="solid-dark-blue"
            text="Add"
            size="md"
            className="text-white w-full sm:w-96 mt-5"
            onClick={addUser}
          />
        </Flex>
      </Flex>
    </Admin>
  );
}
