import { Flex } from "common/widgets/advance/flex";
import React, { useEffect, useState } from "react";
import { Button } from "common/widgets/basic/button";
import { Text } from "common/widgets/basic/text";
import { Modal } from "common/widgets/advance/modal";
import { Dashboard } from "..";
import { API } from "utils/api";
import { http } from "utils/http";
import { useAuth } from "context/AuthContext";
import { toast } from "react-toastify";
import { Loader } from "common/loaders/loader";
import { Toastify } from "common/loaders/toastify";
import axios from "axios";

const headers = ["Name", "Device ID", "View on Map", "Action"];

export function AllDevices() {
  const { currentUser } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [allDevices, setAllDevices] = useState([]);
  const [deviceId, setDeviceId] = useState([]);
  const [deviceIndex, setDeviceIndex] = useState(0);
  const [name, setName] = useState("");

  useEffect(() => {
    getAllDevices();
  }, []);

  // Get all devices, and add token to recognize the user and protect the route
  const getAllDevices = async () => {
    setIsLoading(true);
    const { res, data } = await http.post(
      API.device.getAll,
      {
        userId: currentUser?._id, // required
      },
      {
        token: currentUser?.token,
      }
    );
    if (res.status === 200) {
      setAllDevices(data.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleDeleteDevice = async () => {
    setIsLoading(true);
    const { res, data } = await http.delete(
      API.device.delete,
      {
        deviceId: deviceId,
        userId: currentUser?._id,
      },
      {
        token: currentUser?.token,
      }
    );
    if (res.status === 200) {
      toast.success(data?.message);
      setIsLoading(false);
      // Add device in our database when it is added to Traccer
      getAllDevices();
    } else {
      toast.error(data?.message);
      setIsLoading(false);
    }
  };

  const originalUrl = `https://p-solutions.es:8083`;
  const updateUrl = `${originalUrl}/api/devices/${deviceIndex}`;
  const email = "omartin01@uoc.edu";
  const password = "Traccar123456";
  const credentials = btoa(`${email}:${password}`);

  // Delete from traccer
  const deleteDeviceTraccer = async () => {
    try {
      const res = axios.delete(updateUrl, {
        headers: {
          Authorization: `Basic ${credentials}`,
          "Content-Type": "application/json",
        },
      });
      const resData = await res;
      if (resData.status === 204) {
        // Delete from our database when it is deleted from the traccer
        handleDeleteDevice();
        toast.success("Device deleted from Traccer successfully!");
      } else {
        toast.error("Failed to delete a device");
      }
      console.log(resData);
    } catch (error) {
      toast.error("Something went wrong, please try again");
      console.log(error);
    }
  };

  return (
    <Dashboard text="Your Devices">
      <Toastify />
      <Flex variant="rowCenterCenter" className="mt-10">
        <div className="w-full lg:w-[70rem] overflow-x-auto">
          <table className="min-w-full w-full bg-soft-peach">
            <thead className="bg-dark-charcoal  sticky top-0 z-10">
              <tr>
                {headers?.map((header, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-6 py-3 text-left text-base  font-semibold "
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {allDevices?.map(({ id, name, deviceId }, index) => (
                <tr
                  key={id}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  <td className="px-6  py-4 whitespace-nowrap text-sm  text-left">
                    {name}
                  </td>
                  <td className="px-6  py-4 whitespace-nowrap text-sm  text-left">
                    {deviceId}
                  </td>
                  <td className="px-6  py-4 whitespace-nowrap text-sm  text-left">
                    <Button
                      link={`/dashboard/view-device/${id}`}
                      size="xs"
                      className="bg-purple-blue text-white font-bold py-1 px-4 rounded ml-2"
                    >
                      View
                    </Button>
                  </td>
                  <tr className="w-full ">
                    <td className="py-4 w-full">
                      <Button
                        link={`/dashboard/update-device/${deviceId}`}
                        size="xs"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded "
                      >
                        Edit
                      </Button>
                      <Button
                        size="xs"
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded ml-1"
                        link="#delete-user-modal"
                        // Get values in state, for deletion
                        onClick={() => {
                          setDeviceId(deviceId);
                          setDeviceIndex(id);
                          setName(name);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Modal name="delete-user-modal" size="sm">
          <div>
            <Text
              text="Delete Device?"
              as="h2"
              size="2xl"
              weight="bold"
              className="text-center"
            />
            <Text
              text="Are you sure you want to delete this device?"
              as="h2"
              className="text-center mt-4"
              size="xl"
              weight="bold"
            />
            <Loader loading={isLoading} type="BarLoader" />

            <Button
              size="xs"
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-4 rounded ml-1 w-full mt-8"
              text="Delete"
              onClick={deleteDeviceTraccer}
            />
          </div>
        </Modal>
      </Flex>
    </Dashboard>
  );
}
