import React, { useEffect } from "react";
import { Text } from "common/widgets/basic/text";
import { Flex } from "common/widgets/advance/flex";
import { Button } from "common/widgets/basic/button";
import { http } from "utils/http";
import { API } from "utils/api";
import { useNavigate } from "react-router";
import { useAuth } from "context/AuthContext";

export function Admin({ text, children }) {
  // for navigation to the different url
  const navigate = useNavigate();

  // Get the current user and authentication functions from the useAuth hook.
  const { getCurrentUser, currentUser, isLoading } = useAuth();

  // Check if the user is an admin or not
  useEffect(() => {
    if (!isLoading) {
      if (!currentUser) {
        navigate("/login");
      }
      if (currentUser && currentUser.type !== "admin") {
        navigate("/login");
      }
    }
  }, [currentUser, isLoading]);
  // Logout function

  const handleLogout = async () => {
    const { res } = await http.post(API.auth.logout, {});
    if (res.status === 200) {
      getCurrentUser(null);
      localStorage.removeItem("user");
      navigate("/login");
    }
  };
  // Render the Admin component
  return (
    <div>
      <Flex
        variant="rowBetweenCenter"
        className="sm:mr-5 mt-8 flex-wrap sm:flex-nowrap  justify-center sm:justify-between"
      >
        <Text
          as="h1"
          variant="heading"
          text="Admin Area"
          weight="bold"
          size="3xl"
          className="text-black text-center ml-5"
        />
        <Flex
          variant="rowStartCenter"
          className="gap-2 flex-wrap sm:flex-nowrap justify-center sm:justify-between "
        >
          <Button
            variant="solid-dark-blue"
            text="Add User"
            link="/admin/add-user"
          />
          <Button variant="solid-dark-blue" text="All Users" link="/admin" />
          <Button
            variant="solid-dark-blue"
            text="Contacts"
            link="/admin/contacts"
          />
          <Button
            variant="solid-dark-blue"
            text="Logout"
            onClick={handleLogout}
          />
        </Flex>
      </Flex>

      <Text
        as="h1"
        variant="heading"
        text={text}
        weight="bold"
        size="2xl"
        className="text-black text-center mt-5"
      />

      {children}
    </div>
  );
}
