import React, { useState, useEffect } from "react";
import { Button } from "common/widgets/basic/button";
import { Input } from "common/widgets/basic/input";
import { Text } from "common/widgets/basic/text";
import { Flex } from "common/widgets/advance/flex";
import { toast } from "react-toastify";
import { Toastify } from "common/loaders/toastify";
import { http } from "utils/http";
import { API } from "utils/api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { Loader } from "common/loaders/loader";

/**
 * Renders the login component.
 * @returns {JSX.Element} The login component.
 */
export function Login() {
  const { getCurrentUser, currentUser } = useAuth(); // Get the current user and authentication functions from the useAuth hook.
  const [email, setEmail] = useState(""); // State for storing the email input value.
  const [password, setPassword] = useState(""); // State for storing the password input value.
  const [isLoading, setIsLoading] = useState(false); // State for tracking the loading state of the login process.

  const navigate = useNavigate(); // Get the navigate function from the useNavigate hook.

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.type === "admin") {
        navigate("/admin"); // Redirect to the admin page if the current user is an admin.
      } else {
        navigate("/dashboard"); // Redirect to the dashboard page if the current user is not an admin.
      }
    }
  }, []); // Run this effect only once, on component mount.

  /**
   * Handles the login process.
   * @returns {Promise<void>} A promise that resolves when the login process is complete.
   */
  const handleLogin = async () => {
    setIsLoading(true); // Set the loading state to true.
    const { res, data } = await http.post(API.auth.login, {
      email,
      password,
    }); // Send a login request to the server.

    if (res.status === 200) {
      localStorage.setItem("user", JSON.stringify(data)); // Store the user data in the local storage.
      getCurrentUser(data); // Update the current user state with the logged-in user data.
      if (data?.type === "admin") {
        navigate("/admin"); // Redirect to the admin page if the logged-in user is an admin.
      } else {
        navigate("/dashboard"); // Redirect to the dashboard page if the logged-in user is not an admin.
      }
      setIsLoading(false); // Set the loading state to false.
    } else {
      toast.error(data.message); // Display an error toast message.
      setIsLoading(false); // Set the loading state to false.
    }
  };

  return (
    <Flex variant="colCenterCenter" className="mb-14">
      <Toastify />{" "}
      {/* Render the Toastify component for displaying toast messages. */}
      <Flex
        variant="colCenterStart"
        className="p-5 sm:p-10 shadow-grey w-[calc(100%-1rem)] mt-10  mx-2 sm:w-[30rem]  rounded-xl"
      >
        <Flex variant="rowCenterCenter" className="gap-10 w-full">
          <Button
            variant="solid-dark-blue"
            text="Sign In"
            size="md"
            className="text-white w-32"
            link="/login"
            color="dark-blue"
          />{" "}
          {/* Render the Sign In button. */}
        </Flex>
        <Text
          as="h1"
          className="text-center w-full my-2"
          text="Welcome back!"
          color="dark-blue"
          size="3xl"
          weight="semiBold"
        />{" "}
        {/* Render the welcome message. */}
        <Input
          variant="solid-light-grey"
          label="Email"
          className="w-full sm:w-96"
          containerClass="w-full sm:w-96 mt-2"
          value={email}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="email"
        />{" "}
        {/* Render the email input field. */}
        <Input
          variant="solid-light-grey"
          label="Password"
          className="w-full sm:w-96"
          containerClass="w-full sm:w-96 mt-2 mb-4"
          value={password}
          type="password"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />{" "}
        {/* Render the password input field. */}
        <Loader loading={isLoading} type="BarLoader" />{" "}
        {/* Render the loader component if the login process is loading. */}
        <Button
          variant="solid-dark-blue"
          text="Login"
          size="md"
          className="text-white w-full sm:w-96 mt-5"
          onClick={handleLogin}
        />{" "}
        {/* Render the Login button. */}
      </Flex>
    </Flex>
  );
}
