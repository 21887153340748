import React, { useEffect } from "react";
import { Text } from "common/widgets/basic/text";
import { Flex } from "common/widgets/advance/flex";
import { Button } from "common/widgets/basic/button";
import { http } from "utils/http";
import { API } from "utils/api";
import { useNavigate } from "react-router";
import { useAuth } from "context/AuthContext";

export function Dashboard({ text, children }) {
  // Get currentUser and getUser from global state userAuth
  const { currentUser, getCurrentUser } = useAuth();
  const navigate = useNavigate();

  // If not user, redirect to login page
  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser]);

  //  Handle logout user
  const handleLogout = async () => {
    const { res } = await http.post(API.auth.logout, {});
    if (res.status === 200) {
      getCurrentUser(null);
      localStorage.removeItem("user");
      navigate("/login");
    }
  };

  return (
    <div>
      <Flex
        variant="rowBetweenCenter"
        className="sm:mr-5 mt-8 flex-wrap sm:flex-nowrap  justify-center sm:justify-between"
      >
        <Text
          as="h1"
          variant="heading"
          text="Welcome Back!"
          weight="bold"
          size="3xl"
          className="text-black text-center ml-5"
        />
        <Flex
          variant="rowStartCenter"
          className="gap-2 flex-wrap sm:flex-nowrap justify-center sm:justify-between "
        >
          <Button
            variant="solid-dark-blue"
            text="Add Device"
            link="/dashboard/add-device"
          />
          <Button variant="solid-dark-blue" text="Devices" link="/dashboard" />
          <Button
            variant="solid-dark-blue"
            text="Logout"
            onClick={handleLogout}
          />
          {currentUser?.type === "admin" && (
            <Button variant="solid-dark-blue" text="Admin" link="/admin" />
          )}
        </Flex>
      </Flex>
      <Text
        as="h1"
        variant="heading"
        text={text}
        weight="bold"
        size="2xl"
        className="text-black text-center mt-5"
      />
      {/* Pass children the JXS elements */}
      {children}
    </div>
  );
}
