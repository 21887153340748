import React from "react";
import { cn } from "utils/cn";
import { cva } from "class-variance-authority";

// Define the Flex component

export function Flex({ children, className, variant, onClick, ...props }) {
  return (
    <div
      onClick={onClick}
      {...props}
      className={cn(flexVariants({ variant, className }))}
    >
      {children}
    </div>
  );
}

// Define the flexVariants object using the cva function

const flexVariants = cva("flex", {
  variants: {
    variant: {
      rowStartStart: "flex-row justify-start items-start gap-4",
      rowStartCenter: "flex-row justify-start items-center gap-4",
      rowCenterStart: "flex-row justify-center items-start gap-4",
      rowCenterCenter: "flex-row justify-center items-center gap-4",
      rowBetweenStart: "flex-row justify-between items-start gap-4",
      rowBetweenCenter: "flex-row justify-between items-center gap-4",
      rowEndCenter: "flex-row justify-end items-center gap-4",
      rowEndStart: "flex-row justify-end items-start gap-4",
      colStartCenter: "flex-col justify-start items-center gap-4",
      colCenterCenter: "flex-col justify-center items-center gap-4",
      colCenterStart: "flex-col justify-center items-start gap-4",
      colStartStart: "flex-col justify-start items-start gap-4",
      colBetweenCenter: "flex-col justify-between items-center gap-4",
      colBetweenStart: "flex-col justify-between items-start gap-4",
    },
  },
  defaultVariants: {
    variant: "rowStartCenter",
  },
});
