// Backend API URL
const API_HOST = "https://p-solutions.es";

// Default configuration for the fetch method
const defaultConfig = {
  useFormURLEncoded: false,
};

// Build the body of the request
function buildBody(params) {
  const body = new URLSearchParams();
  for (let d in params) {
    body.append(d, params[d]);
  }
  return body;
}

// Fetch method to make requests to the server

export async function fetchMethod(method, endpoint, params, config) {
  const requestConfig = {
    ...defaultConfig,
    ...config,
  };

  // Build the URL

  const url = `${API_HOST}${endpoint}`;

  // Make the request to the server
  try {
    const headers = {};

    let body;
    if (params instanceof FormData) {
      body = params;
    } else {
      headers["Content-Type"] = "application/json";
      if (method !== "GET") {
        body = requestConfig.useFormURLEncoded
          ? buildBody(params || {})
          : JSON.stringify(params);
      }
    }
    // Add the token to the headers if it is provided in the configuration
    if (config?.token) {
      headers["Authorization"] = config.token;
    }

    // Make the request to the server
    const res = await fetch(url, {
      method,
      credentials: "include",
      headers,
      body,
    });

    // Parse the response

    let data;
    try {
      data = await res.json();
    } catch (error) {
      data = null;
    }
    // Return the response and data

    return { res, data };
  } catch (error) {
    throw new Error(String(error));
  }
}
// HTTP methods to make requests to the server

export const http = {
  // Get method to make a GET request to the server
  get: async (endpoint, params = {}, config = {}) => {
    let url = endpoint;

    // Format the parameters

    let formattedParams = Object.entries(params).reduce((acc, curr, index) => {
      if (typeof curr[1] === "object") {
        return `${acc}${index > 0 ? "&" : "?"}${curr[1]
          .map((value) => `${curr[0]}=${encodeURIComponent(value)}`)
          .join("&")}`;
      } else {
        return `${acc}${index > 0 ? "&" : "?"}${curr[0]}=${encodeURIComponent(
          curr[1]
        )}`;
      }
    }, "");
    // Add the parameters to the URL
    if (Object.keys(params).length) {
      url = `${endpoint}${formattedParams}`;
    }
    return fetchMethod("GET", url, {}, config);
  },
  // Post method to make a POST request to the server
  post: (endpoint, params, config = {}) =>
    fetchMethod("POST", endpoint, params, config),
  patch: (endpoint, params, config = {}) =>
    fetchMethod("PATCH", endpoint, params, config),
  put: (endpoint, params, config = {}) =>
    fetchMethod("PUT", endpoint, params, config),
  delete: (endpoint, params, config = {}) =>
    fetchMethod("DELETE", endpoint, params, config),
};
