import React from "react";
import { Flex } from "common/widgets/advance/flex";
import { Button } from "common/widgets/basic/button";
import { Text } from "common/widgets/basic/text";

export function Header() {
  return (
    <Flex
      variant="colCenterStart"
      className={` p-10  bg-cover bg-center h-[calc(100vh-4.75rem)]  from-dark-blue to-nile-blue 
            bg-[linear-gradient(to_right,rgba(134,139,255,.75),rgba(67,24,255,.75)),url("../public/assets/imgs/header-2.jpg")]`}
    >
      <Text
        size="4xl"
        as="h1"
        weight="semiBold"
        color="white"
        className="leading-tight text-4xl md:text-6xl"
      >
        Realtime GPS trace on a map
      </Text>
      <Text
        size="2xl"
        as="h2"
        weight="regular"
        color="white"
        className="leading-tight font-semibold"
        text="Login to continue!"
      />

      <Button
        text="Get Started!"
        link="/about"
        className="mt-5 font-semibold text-xl"
      />
    </Flex>
  );
}
