import React, { useState } from "react";
import { Button } from "common/widgets/basic/button";
import { Input } from "common/widgets/basic/input";
import { Text } from "common/widgets/basic/text";
import { Flex } from "common/widgets/advance/flex";
import { toast } from "react-toastify";
import { Toastify } from "common/loaders/toastify";
import { Dashboard } from "..";
import { useAuth } from "context/AuthContext";
import { API } from "utils/api";
import { http } from "utils/http";
import { Loader } from "common/loaders/loader";
import axios from "axios";

export function AddDevice() {
  // Get the current user from the AuthContext
  const { currentUser } = useAuth();

  // States
  const [name, setName] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Add in our database
  const addDeviceToDatabase = async (id) => {
    setIsLoading(true);
    // Add device to our database
    const { res, data } = await http.post(
      API.device.add,
      // API.device.add is the endpoint to add a device in the database, pass the required data
      {
        name,
        deviceId,
        userId: currentUser?._id,
        id: id,
      },
      // currentUser?.token is the token of the current user
      {
        token: currentUser?.token,
      }
    );
    if (res.status === 201) {
      // 201 is the status code for created
      toast.success(data?.message);
      setIsLoading(false);
    } else {
      // If the status code is not 201, show an error toast
      toast.error(data?.message);
      setIsLoading(false);
    }
  };

  // Traccer API
  const originalUrl = `https://p-solutions.es:8083`;
  // The Traccer API endpoint to add a device
  const url = `${originalUrl}/api/devices`;
  const email = "omartin01@uoc.edu";
  const password = "Traccar123456";

  const credentials = btoa(`${email}:${password}`);

  // Add device to the traccer
  const addTraccerDevice = async () => {
    // Check if the name and deviceId are not empty
    if (!name || !deviceId) {
      toast.error("Please fill all the fields.");
      return;
    }

    try {
      // Send a post request to the Traccer API to add a device
      const res = axios.post(
        url,
        {
          id: 0,
          name: name,
          uniqueId: deviceId,
          status: "string",
          disabled: false,
          lastUpdate: "2019-08-24T14:15:22Z",
          positionId: 0,
          groupId: 0,
          phone: "string",
          model: "string",
          contact: "string",
          category: "string",
          attributes: {},
        },
        // Pass the basic authorization header with the email and password
        {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      );
      // Wait for the response
      const resData = await res;
      if (resData.status === 200) {
        //  add in our database when it is added to the traccer api
        await addDeviceToDatabase(resData?.data?.id);
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast.error("Device already exists, Please try another.");
      }
    }
  };

  return (
    <Dashboard text="">
      <Flex variant="colCenterCenter" className="mb-14">
        {/* Add a toastify component */}
        <Toastify />
        <Flex
          variant="colCenterStart"
          className="p-5 sm:p-10 shadow-grey w-[calc(100%-1rem)]   mx-2 sm:w-[30rem]  rounded-xl"
        >
          <Text
            as="h1"
            className="text-center w-full"
            text="Add New Device"
            color="dark-blue"
            size="3xl"
            weight="semiBold"
          />
          {/* Add input field for name */}
          <Input
            variant="solid-light-grey"
            label="Name"
            className="w-full sm:w-96"
            containerClass="w-full sm:w-96 mt-4"
            value={name}
            type="text"
            placeholder="name"
            onChange={(e) => setName(e.target.value)}
          />
          {/* Add input field device id */}

          <Input
            variant="solid-light-grey"
            label="Device ID"
            className="w-full sm:w-96"
            containerClass="w-full sm:w-96 mt-4"
            value={deviceId}
            type="text"
            placeholder="device id"
            onChange={(e) => setDeviceId(e.target.value)}
          />

          <Loader loading={isLoading} type="BarLoader" />
          {/* Add a button to add the device */}
          <Button
            variant="solid-dark-blue"
            text="Add"
            size="md"
            className="text-white w-full sm:w-96 mt-10 mb-8"
            onClick={addTraccerDevice} // Call the addTraccerDevice function on click
          />
        </Flex>
      </Flex>
    </Dashboard>
  );
}
