export const API = {
  auth: {
    login: "/api/user/login",
    logout: "/api/user/logout",
  },
  admin: {
    user: {
      add: "/api/admin/user/add",
      update: "/api/admin/user/update",
      delete: "/api/admin/user/delete",
      get: "/api/admin/user/get",
      getAll: "/api/admin/users/get",
    },
  },
  contact: {
    add: "/api/contact/add",
    getAll: "/api/contact/all",
    delete: "/api/contact/delete",
  },
  device: {
    add: "/api/device/add",
    get: "/api/device/single",
    getAll: "/api/device/all",
    update: "/api/device/update",
    delete: "/api/device/delete",
  },
};
