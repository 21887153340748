// Import necessary dependencies from React and react-router-dom
import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Create a context for authentication
const AuthContext = createContext({
  currentUser: null,
  getCurrentUser: () => {},
  isLoading: true,
});

// Custom hook to access the authentication context
export const useAuth = () => useContext(AuthContext);

// Authentication provider component
export function AuthProvider({ children }) {
  // State variables for current user and loading status
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Access the current location and navigation functions from react-router-dom
  const navigate = useNavigate();
  const location = useLocation();

  // Effect hook to get the local user on component mount
  useEffect(() => {
    getLocalUser();
  }, []);

  // Function to get the local user from local storage
  const getLocalUser = () => {
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user && location.pathname.includes("dashboard")) {
      navigate("/login");
    }
    if (user) {
      if (
        !location.pathname.includes("dashboard") &&
        !location.pathname.includes("admin")
      ) {
        if (user.type === "admin") {
          navigate("/admin");
        } else {
          navigate("/dashboard");
        }
      }
    }
    setCurrentUser(user);
    setIsLoading(false);
  };

  // Function to set the current user
  const getCurrentUser = (user) => {
    setCurrentUser(user);
  };

  // Value object to be passed as the value of the authentication context
  const value = {
    currentUser,
    getCurrentUser,
    isLoading,
  };

  // Render the authentication context provider with the provided children
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
