import { cva } from "class-variance-authority";
import { Flex } from "common/widgets/advance/flex";
import React from "react";
import { cn } from "utils/cn";

// Define the Input component
export function Input({
  className,
  variant,
  size,
  label,
  icon,
  containerClass,
  textArea,
  rows,
  cols,
  ...props
}) {
  // Render the Input component
  if (textArea) {
    return (
      <Flex variant="colStartStart" className={`gap-0 ${containerClass}`}>
        {label && (
          <label className="text-sm text-dark-grey font-dmSans font-normal">
            {label}
          </label>
        )}
        <textarea
          rows={rows}
          cols={cols}
          {...props}
          className={cn(inputVariants({ variant, size, className }))}
        />
      </Flex>
    );
  } else {
    // Render the input component
    return (
      <Flex variant="colStartStart" className={`gap-0 ${containerClass}`}>
        {label && (
          <label className="text-sm text-dark-grey font-dmSans font-normal">
            {label}
          </label>
        )}
        <input
          {...props}
          className={cn(inputVariants({ variant, size, className }))}
        />
      </Flex>
    );
  }
}
// Define the inputVariants object using the cva function

const inputVariants = cva("", {
  variants: {
    variant: {
      "solid-light-grey":
        "font-dmSans bg-light-grey text-sm px-4 py-[.7rem] rounded-xs border-none text-dark-grey hover:opacity-80 focus:border-none focus:outline-none disabled:bg-french-grey disabled:cursor-not-allowed  disabled:text-dark-grey",
      "rounded-light-grey":
        "font-dmSans bg-light-grey text-sm px-6 py-[.7rem] rounded-full  border-none text-dark-grey hover:opacity-80 focus:border-none focus:outline-none",
      outline:
        "font-dmSans bg-white   p-3 w-full focus:outline-none focus:border-none",
      standard: "font-dmSans bg-white border-2 border-black",
      danger: "font-dmSans bg-white border-2 border-black",
      underline: "font-dmSans bg-white border-2 border-black",
    },
    size: {
      xs: "px-2 py-1",
      sm: "px-2 py-1",
      md: "px-6 py-2",
      lg: "px-6 py-3",
      xl: "px-6 py-3",
    },
  },
  defaultVariants: {
    variant: "rounded-light-grey",
  },
});
