import { Text } from "common/widgets/basic/text";
import { Layout } from "layout";
import { Header } from "layout/landing/header";
import React from "react";

export function Home() {
  return (
    <div>
      <Text
        as="h1"
        size="2xl"
        weight="bold"
        text="Multi purpose Geo-Location Platform"
        className="text-center bg-white shadow-grey pt-4"
      />
      <Layout type="Landing">
        <Header />
      </Layout>
    </div>
  );
}
