import BarLoader from "react-spinners/BarLoader";
import BeatLoader from "react-spinners/BeatLoader";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
// Import the loaders from the react-spinners library

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  backgroundColor: "white",
};

// Define the loaders object
const loaders = {
  BarLoader,
  ClimbingBoxLoader,
  BeatLoader,
};

// Define the Loader component

export function Loader({ loading, type }) {
  const LoaderComponent = loaders[type];

  // Return the LoaderComponent with the loading prop
  return (
    <LoaderComponent
      color="#11047A"
      loading={loading}
      cssOverride={override}
      height={5}
      aria-label="Loading Spinner"
      data-testid="loader"
      width={250}
    />
  );
}
