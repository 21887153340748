import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Cookie } from "common/cookie";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* Add cookie to show everywhere in the project */}
    <Cookie />
    {/* App main router entry point, contain all the router */}
    <App />
  </React.StrictMode>
);

reportWebVitals();
