import React from "react";
import { Flex } from "common/widgets/advance/flex";
import { Text } from "common/widgets/basic/text";
import { Button } from "common/widgets/basic/button";

export function Footer() {
  return (
    <Flex
      variant="rowBetweenStart"
      className="bg-black p-10 gap-10 sm:gap-0 flex-col items-center sm:flex-row sm:items-start"
    >
      <Text
        as="h1"
        variant="leading"
        weight="bold"
        size="2xl"
        className="text-soft-peach"
      >
        Multi purpose <br /> Geo-Location Platform
      </Text>
      <div>
        <Flex variant="colStartCenter">
          <Text
            as="h1"
            variant="heading"
            text="Menu"
            weight="bold"
            size="lg"
            className="text-soft-peach border-b-2"
          />
          <Button
            variant="standard"
            text="Home"
            className="text-soft-peach"
            link="/"
          />
          <Button
            variant="standard"
            text="Login"
            className="text-soft-peach"
            link="/login"
          />
        </Flex>
      </div>
      <div>
        <Flex variant="colStartCenter">
          <Text
            as="h1"
            variant="heading"
            text="Info"
            weight="bold"
            size="lg"
            className="text-soft-peach border-b-2"
          />
          <Button
            variant="standard"
            text="Contact"
            className="text-soft-peach"
            link="/contact"
          />
          <Button
            variant="standard"
            text="About Us"
            className="text-soft-peach"
            link="/about"
          />
        </Flex>
      </div>
    </Flex>
  );
}
